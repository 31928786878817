<template>
  <div class="account-table">
    <el-table
      border
      :data="list"
      style="width: 100%"
      :row-style="{ height: 0 }"
    >
      <!-- <el-table-column type="index" label="ID" align="center"></el-table-column> -->
      <el-table-column
        prop="tempId"
        label="ID"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
        align="center"
      ></el-table-column>
      <el-table-column prop="productPlanName" label="方案" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.productPlanName || "无" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        label="手机号"
        align="center"
        min-width="120"
      ></el-table-column>
      <!-- <el-table-column
        prop="channelName"
        label="渠道"
        align="center"
      ></el-table-column> -->
      <el-table-column
        prop="renewAmount"
        label="应还款金额(元)"
        align="center"
        min-width="120"
      >
        <template slot-scope="scope"
          ><span>{{ scope.row.renewAmount / 100 }}</span></template
        >
      </el-table-column>

      <el-table-column
        prop="renewDays"
        label="续期天数"
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        prop="renewFee"
        label="续期费用"
        align="center"
      ></el-table-column> -->
      <!-- <el-table-column prop="renewType" label="还款方式" align="center">
        <template slot-scope="scope">{{
          scope.row.renewType | mapRepaymentType
        }}</template>
      </el-table-column> -->
      <el-table-column prop="paymentTime" label="借款日期" align="center">
        <template slot-scope="scope">{{
          scope.row.paymentTime | minifyDate
        }}</template>
      </el-table-column>
      <el-table-column prop="timeLimit" label="应还日期" align="center">
        <template slot-scope="scope">{{
          scope.row.timeLimit | minifyDate
        }}</template>
      </el-table-column>
      <!-- <el-table-column prop="timeLimit" label="还款日期" align="center">
        <template slot-scope="scope">{{
          scope.row.repaymentTime | minifyDate
        }}</template>
      </el-table-column> -->
      <!-- <el-table-column prop="amount" label="已还金额(元)" align="center">
        <template slot-scope="scope">{{
          Math.floor(scope.row.amount / 100)
        }}</template>
      </el-table-column> -->
      <el-table-column
        prop="stagingPosition"
        label="期数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="orderCount"
        label="笔数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createAt"
        label="续期创建时间"
        align="center"
        min-width="110"
      ></el-table-column>
      <el-table-column
        prop="finance"
        label="复审客服"
        align="center"
      ></el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
          <span :style="'color: ' + mapStatus(scope.row.status).color">{{
            scope.row.status | mapStatusText
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="renewType" label="续期方式" align="center">
        <template slot-scope="scope">
          {{scope.row.renewType | RenewType}}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="label" label="备注" align="center"></el-table-column> -->
      <el-table-column label="操作" header-align="center" min-width="140">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-search"
            circle
            @click="$emit('view', scope.row, false)"
          ></el-button>
          <el-button
            v-if="!scope.row.repaymentType"
            type="primary"
            size="mini"
            icon="el-icon-search"
            circle
            @click="showImg(scope.row)"
          ></el-button>
          <el-button
            v-if="scope.row.status === 0"
            type="success"
            size="mini"
            icon="el-icon-edit"
            circle
            @click.native.prevent="handleShow(scope.$index, scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 展示详情 -->
    <renewalAudit
      :is-show="showPaid"
      :edit-data="editData"
      @cancel="showPaid = false"
      @submit="submitPaid"
    ></renewalAudit>
  </div>
</template>

<script>
import renewalAudit from "./renewal-audit";
import service from "../api";
import { base, baseUrl } from "@/api/env";
import pagination from "@/mixins/pagination";
const mapStatus = status => {
  switch (status) {
    case -1:
      return {
        text: "已拒绝",
        color: "red"
      };
    case 0:
      return {
        text: "待审核",
        color: "black"
      };
    case 1:
      return {
        text: "已通过",
        color: "green"
      };
  }
};

export default {
  components: {
    renewalAudit
  },
  created() {
    this.$parent.getList({ pageNum: this.currentPage });
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    //拿一下currentPage的值
    currentPage: {
      type: Number,
      default: () => 1
    }
  },
  data() {
    return {
      showPaid: false,
      editData: {}
    };
  },
  methods: {
    handleShow(index, row) {
      this.showPaid = true;
      this.editData = row;
    },
    submitPaid(data) {
      let _this = this;
      let { renewId } = this.editData;
      let { pass = null } = data;
      // this.afterSubmit({ pageNum: this.currentPage })

      return service.setRenew({ id: renewId, ...data }).then(res => {
        if (res.code == "00000") {
          _this.afterSubmit({ pageNum: this.currentPage });
        }
      });
    },
    showImg(row) {
      this.$alert(
        '<img src="' +
          base +
          "api/common/attach/" +
          row.attachId +
          '" alt="" srcset="" width="400px">',
        "还款凭证",
        {
          dangerouslyUseHTMLString: true
        }
      );
    },
    // 提交后操作
    afterSubmit(refreshParam) {
      this.showPaid = false;
      this.$notify({ type: "success", message: "操作成功！" });
      this.$parent.getList({ pageNum: this.currentPage });
    },
    mapStatus(status) {
      switch (status) {
        case -1:
          return {
            text: "已拒绝",
            color: "red"
          };
        case 0:
          return {
            text: "待审核",
            color: "black"
          };
        case 1:
          return {
            text: "已通过",
            color: "green"
          };
      }
    }
  },
  filters: {
    mapStatusText: s => mapStatus(s).text,
    mapRepaymentType(t) {
      switch (t) {
        case 0:
          return "线下";
        case 1:
          return "线上";
        case 2:
          return "后台还款";
        default:
          return "未知";
      }
    },
    RenewType(t){
      switch (t) {
        case 0:
          return "线下";
        case 1:
          return "后台续期";
        default:
          return "未知";
      }
    },
    minifyDate(d) {
      const now = new Date();
      if (typeof d == "string") {
        d = new Date(d);
      }
      let l = [];
      if (d.getFullYear() !== now.getFullYear()) {
        l.push(d.getFullYear());
      }
      l.push((d.getMonth() + 1 + "").padStart(2, "0"));
      l.push((d.getDate() + "").padStart(2, "0"));

      return l.join("-");
    }
  }
};
</script>

<style lang="scss" scoped>
.account-table {
  .psd-cell {
    width: 100%;
    position: relative;
    i {
      position: absolute;
      right: 0;
    }
  }
}
.el-table th {
  text-align: center !important;
}
.el-message-box__header {
  text-align: center;
}
</style>
